import { render, staticRenderFns } from "./BarcodeInfoAdd.vue?vue&type=template&id=6da826b6&scoped=true"
import script from "./BarcodeInfoAdd.vue?vue&type=script&lang=js"
export * from "./BarcodeInfoAdd.vue?vue&type=script&lang=js"
import style0 from "./BarcodeInfoAdd.vue?vue&type=style&index=0&id=6da826b6&prod&lang=css"
import style1 from "./BarcodeInfoAdd.vue?vue&type=style&index=1&id=6da826b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6da826b6",
  null
  
)

export default component.exports